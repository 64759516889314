import React, { useState, useRef, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Text, Placeholder, RichText } from '@sitecore-jss/sitecore-jss-react';
import { Formik } from 'formik';
import { withTranslation } from 'react-i18next';

import { indexOfSelectedCard, redirectToUrl, isFrench } from '../../services/utils';
import { Button, ButtonBlock, Loader } from '../../atoms';
import { Error, TextInput, Note } from '../../molecules';
import api from '../../services/api';
import {
  setShoppingCart,
  addShoppingCartMessage,
  setGuestShoppingCartToken,
  setGuestShoppingCartPayload,
} from '../../redux/user/actions';
import upIcon from '../../assets/images/up.svg';
import useDetectOutsideClick from '../../hooks/useDetectOutsideClick';
import {
  E_PURSE_PRD_FAMILY,
  SINGLE_FARE_CRD_CART_ITEM_NAME,
  NEWMEDIA_VISIBLE_ID,
  PRICE_BUTTONS,
  SINGLE_FARE_CRD_CART_FAMILY_NAME,
} from '../../constants/product.constants';
import './addfunds.scss';
import routes from '../../constants/routes';

function AddFunds({ rendering, t }) {
  const businessRules = useSelector((state) => state.businessRules?.rules) || [];
  const [orderToBeAddedToCart, setOrderToBeAddedToCart] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalProducts, setTotalProducts] = useState(null);
  const userInfo = useSelector((state) => state.user);
  const visibleId = useSelector((state) => state.user.selectedCard.visibleId);
  const customerId = useSelector((state) => state.user.customerId);
  const shoppingCart = useSelector((state) => state.user.shoppingCart);
  const guestSCT = useSelector((reduxState) => reduxState.user.guestShoppingCartToken);
  const anonymous = useSelector((state) => state.user && state.user.anonymous);
  const pendingProducts = useSelector((state) => state.user.pendingProducts) || {
    list: [],
    orders: [],
  };
  const [isAccordionClicked, setIsAccordionClicked] = useState(false);
  const focusDiv = useRef();
  const guestUserInfo = useSelector((reduxState) => reduxState.user.guestUserInfoEnabled);
  const dispatch = useDispatch();
  const cardDropdownRef = useRef(null);
  const [isArrowIconClicked, openDropdown] = useDetectOutsideClick(cardDropdownRef, true);
  const onClickSelect = () => {
    openDropdown(!isArrowIconClicked);
    setIsAccordionClicked(true);
  };
  const iconText = isArrowIconClicked ? t('expandA11y') : t('collapseA11y');
  // const iconText = isArrowIconClicked ? t('collapseA11y') : t('expandA11y');
  const currentBalance =
    userInfo.fareMedias[indexOfSelectedCard(userInfo.fareMedias, userInfo.selectedCard.visibleId)]
      ?.currentBalance;
  const AddFundsMax = businessRules?.AddFundsMax ? businessRules?.AddFundsMax : 1000;
  const AddFundsMin = businessRules?.AddFundsMin ? businessRules?.AddFundsMin : 0.05;
  const checkIsNewCardExist = () => {
    if (shoppingCart && shoppingCart.items && shoppingCart.items.length) {
      let check = shoppingCart.items.filter(
        (el) =>
          el.productFamily === SINGLE_FARE_CRD_CART_FAMILY_NAME ||
          el.visibleId === NEWMEDIA_VISIBLE_ID
      );
      return check.length ? true : false;
    }
    return false;
  };
  useEffect(() => {
    if (orderToBeAddedToCart[0]?.OrderLines?.length > 0) {
      setLoading(false);
      orderToBeAddedToCart.forEach((el) => {
        dispatch(setShoppingCart(el, true));
      });
      let hasNewCard = orderToBeAddedToCart[0]?.OrderLines.find(
        (el) =>
          el?.Product?.ProductFamily === SINGLE_FARE_CRD_CART_FAMILY_NAME &&
          el?.Product?.ProductName.toUpperCase() === SINGLE_FARE_CRD_CART_ITEM_NAME
      );
      if (hasNewCard) {
        dispatch(addShoppingCartMessage(t('orderACardCheckoutNotification')));
      }
      dispatch(addShoppingCartMessage(t('shoppingCartNotificationsAddFunds')));
    }
  }, [orderToBeAddedToCart]);

  useEffect(() => {
    if (isAccordionClicked) {
      if (focusDiv.current) {
        focusDiv.current.focus();
      }
    }
  }, [isArrowIconClicked]);

  const isFundsAlreadyInCartForSelectedCard = useSelector(
    (state) =>
      state.user.shoppingCart.items.filter(
        (item) =>
          item.productFamily === E_PURSE_PRD_FAMILY &&
          item.visibleId === state.user.selectedCard.visibleId
      ).length
  );
  const checkAnyNonNewmediaItemInCart = () => {
    if (shoppingCart && shoppingCart.items && shoppingCart.items.length) {
      let check = shoppingCart.items.filter((el) => el.visibleId !== NEWMEDIA_VISIBLE_ID);
      return check.length ? true : false;
    }
    return false;
  };
  const showErrorMessage = () => (
    <div className="add-funds-warning-note">
      <RichText field={rendering?.fields?.ErrorNote || 'ErrorNote'} />
      <div className="add-funds-warning-buttons">
        {rendering?.fields?.CheckOutLink ? (
          <span className="add-funds-checkout">
            <Button onClick={() => redirectToUrl(routes?.checkout)}>
              <Text field={rendering?.fields?.CheckOutLink} />
            </Button>
          </span>
        ) : null}
        <div className="add-funds-return">
          <Button white firstOrder onClick={() => redirectToUrl(routes.orderNewCard)}>
            <Text field={rendering?.fields?.BackToBuyAPrestoCard} />
          </Button>
        </div>
      </div>
    </div>
  );

  const validate = (values) => {
    const { amount = '', customAmount = '' } = values;
    const totalAmount = customAmount
      ? parseFloat(customAmount) + parseFloat(currentBalance)
      : parseFloat(amount) + parseFloat(currentBalance);

    if (!amount && !customAmount) return { amount: t('addFundsAmountValue') };
    if (customAmount && (!/^\d+(\.\d{0,2})?$/i.test(customAmount))) {
      return { customAmount: t('autoloadInvalidDollarValue') };
    }
    if (
      customAmount &&
      (totalAmount > parseFloat(AddFundsMax) ||
        customAmount < parseFloat(AddFundsMin) ||
        customAmount > parseFloat(AddFundsMax) ||
        !/^\d{0,4}(\.\d{0,1}\d{0,1}){0,1}$/i.test(customAmount))
    ) {
      return { customAmount: t('addFundsBalanceThreshold') };
    }
    return {};
  };
  const checkIsNewMediaWithFundsInCart = () => {
    if (shoppingCart && shoppingCart.items && shoppingCart.items.length) {
      let check = shoppingCart.items.filter(
        (el) => el.visibleId === NEWMEDIA_VISIBLE_ID && el.productFamily === E_PURSE_PRD_FAMILY
      );
      return check.length ? true : false;
    }
    return false;
  };
  const Heading = () => (
    <div className="autoload-subtext">
      <Text field={rendering.fields.description} />
    </div>
  );
  const manageOrderDetails = (id) => {
    setOrderToBeAddedToCart((prevOrderId) => [...prevOrderId, id]);
  };
  const addToCartAPI = (products, fundAmt, index) => {
    let copyOfIndex = index;
    let product = products[index];
    let IsOrderCard = rendering?.fields?.IsOrderCard?.value;
    let visibleIdCheck = rendering?.fields?.IsOrderCard?.value ? NEWMEDIA_VISIBLE_ID : visibleId;
    let IsNewCardAdded = checkIsNewCardExist();
    if (product?.ProductId) {
      const productId = product?.ProductId;
      const amt =
        product.ProductName === SINGLE_FARE_CRD_CART_ITEM_NAME ? product.ProductPrice : fundAmt;
      api
        .addFundToCart({
          customerId: customerId !== undefined ? customerId : null,
          visibleId: visibleIdCheck,
          productId,
          price: amt,
          quantity: 1,
          IsNewCardAdded,
          IsOrderCard,
          Sct: guestUserInfo?.isGuestLogin && guestSCT?.sct ? guestSCT.sct : '',
          //    Cct: guestUserInfo?.isGuestLogin && guestSCT?.cct ? guestSCT.cct : '',
          IsGuestUser: guestUserInfo?.isGuestLogin ? true : false,
          IsAnonymousUser: anonymous ? true : false,
        })
        .then((res) => {
          manageOrderDetails(res.data.ShoppingCart.Order);
          setLoading(false);
          // if (guestUserInfo?.isGuestLogin) {
          dispatch(setGuestShoppingCartToken(res.data.Cookies));
          // dispatch(
          //   setGuestShoppingCartPayload({
          //     productId,
          //     price: res.data.ShoppingCart.Order.TotalAmount,
          //   })
          // );
          // }

          // if (index + 1 !== products.length) {
          //   copyOfIndex += 1;
          //   addToCartAPI(products, fundAmt, copyOfIndex);
          // }
        })
        .catch((err) => {
          setLoading(false);
          if (index + 1 === products.length) {
            // setLoading(false);
          } else {
            // copyOfIndex += 1;
            // addToCartAPI(products, fundAmt, copyOfIndex);
          }
          //console.log(err);
        });
    } else {
      setLoading(false);
      if (index + 1 === products.length) {
        setLoading(false);
      } else {
        // copyOfIndex += 1;
        // addToCartAPI(products, fundAmt, copyOfIndex);
      }
    }
  };
  const FormContent = () => (
    <Formik
      initialValues={{
        amount: '',
        customAmount: '',
      }}
      validate={validate}
      validateOnChange={false}
      onSubmit={(values) => {
        let IsOrderCard = rendering?.fields?.IsOrderCard?.value;
        let visibleIdCheck = rendering?.fields?.IsOrderCard?.value
          ? NEWMEDIA_VISIBLE_ID
          : visibleId;
        let IsNewCardAdded = checkIsNewCardExist();
        setLoading(true);
        api
          .getEligibleFunds({ visibleId: visibleIdCheck, IsOrderCard, IsNewCardAdded })
          .then((response) => {
            if (response.data.Success) {
              const eligibleProducts = response.data.eligibleProducts || [];
              if (eligibleProducts.length) {
                setTotalProducts(eligibleProducts.length);
                setOrderToBeAddedToCart([]);
                addToCartAPI(eligibleProducts, values.amount || values.customAmount, 0);
              }
            } else {
              setLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            //console.log(err);
          });
      }}
    >
      {({ errors, touched, handleSubmit, setFieldValue, values }) => {
        if (values.amount && values.customAmount) {
          setFieldValue('amount', '');
        }
        let checkAnyNonNewmediaItemInCartInOrderACard =
          rendering?.fields?.IsOrderCard?.value && checkAnyNonNewmediaItemInCart();
        let isButtonDisabled =
          isFundsAlreadyInCartForSelectedCard ||
          checkIsNewMediaWithFundsInCart() ||
          checkAnyNonNewmediaItemInCartInOrderACard;

        function onKeyDown(keyEvent) {
          if ((((keyEvent.charCode || keyEvent.keyCode) === 13) ||
            ((keyEvent.charCode || keyEvent.keyCode) === 32)
          ) && isButtonDisabled) {
            keyEvent.preventDefault();
          }
        }
        return (
          <form onSubmit={handleSubmit} onKeyDown={onKeyDown}>
            <div className="addfunds">
              {errors.amount ? <Error title={errors.amount} /> : null}
              <div className="labels">
                <div className="amount">
                  <Text field={rendering.fields.amountText} />
                </div>
                <div className="custom-amount">
                  <label htmlFor="customAmount">
                    <Text field={rendering.fields.customAmountText} />
                  </label>
                </div>
              </div>
              <div className="addfunds-amounts">
                <div role="group" className="addfunds-option">
                  {PRICE_BUTTONS.map((value, index) => (
                    <button
                      className={values.amount === value && 'active'}
                      aria-pressed={values.amount === value ? 'true' : 'false'}
                      tabIndex="0"
                      type="button"
                      name="amount"
                      id={index}
                      onClick={() => {
                        setFieldValue('amount', value);
                        setFieldValue('customAmount', '');
                      }}
                    >
                      {`${isFrench() ? `${value}$` : `$${value}`}`}
                    </button>
                  ))}
                </div>
                <div className="addfunds-input">
                  <div className="custom-amount-mobile">
                    <Text field={rendering.fields.customAmountText} />
                  </div>
                  <TextInput
                    withMoneyIcon
                    whiteBg
                    name="customAmount"
                    errors={errors}
                    touched={touched}
                    bellowText={rendering.fields.customAmountRange}
                  />
                </div>
              </div>
            </div>

            <ButtonBlock>
              <ButtonBlock right>
                <Button
                  type="submit"
                  isDisabled={!!isButtonDisabled}
                  buttonTextA11y={
                    isButtonDisabled
                      ? rendering.fields.addedToCartButtonTextA11y.value
                      : rendering.fields.addToCartButtonTextA11y.value
                  }
                >
                  {isButtonDisabled ? (
                    <Text field={rendering.fields.addedToCartButtonText} />
                  ) : (
                    <Text field={rendering.fields.addToCartButtonText} />
                  )}
                </Button>
              </ButtonBlock>
            </ButtonBlock>
          </form>
        );
      }}
    </Formik>
  );
  const AddFundsWithAccordion = () => (
    <div
      className="accordion-section"
      id={`${rendering.fields.IsOrderCard.value ? 'order-a-card-accordion' : ''}`}
    >
      <button
        onClick={onClickSelect}
        ref={focusDiv}
        aria-expanded={isArrowIconClicked}
        className="accordion-heading"
        id="AddFundsAccordionID"
        aria-controls="AddFundsAccordionRegionID"
      >
        <Heading />
        <img alt={iconText} className={isArrowIconClicked ? '' : 'up'} src={upIcon} />
      </button>
      {isArrowIconClicked && (
        <div
          role="region"
          className="accordion-wrapper"
          id="AddFundsAccordionRegionID"
          aria-labelledby="AddFundsAccordionID"
        >
          <FormContent />
        </div>
      )}
    </div>
  );
  return [
    <div className="loadMyCard-wrapper">
      <div className="autoload-tab">
        {(pendingProducts.orders?.length > 0 && rendering.fields.note.value != "") && (
          <Note withIcon cssClass="note-top-margin note-bottom-margin">
            <RichText field={rendering.fields.note} />
          </Note>
        )}
        {checkIsNewCardExist() && !rendering.fields.IsOrderCard.value ? showErrorMessage() : null}
        <div className="add-funds-wrapper">
          <div
            className="autoload-title"
            id={`${rendering.fields.IsOrderCard.value ? 'autoload-titleID' : ''}`}
          >
            <Text field={rendering.fields.addFundsText} />
          </div>
          {rendering.fields.IsOrderCard.value ? (
            <AddFundsWithAccordion />
          ) : (
            [<Heading />, <FormContent />]
          )}
        </div>
      </div>
      <div className="learn-note">
        <Placeholder name="presto-autoload-dds" rendering={rendering} />
      </div>
    </div>,
    loading ? <Loader /> : null,
  ];
}

export default withTranslation()(AddFunds);

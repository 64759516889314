import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import api from '../../services/api';
import handleServerSuccessError from '../../services/handleServerSuccessError';
import { Button, ButtonBlock } from '../../atoms';
import { Error, Modal, TextInput, Note } from '../../molecules';

const AddPrestoCard1 = (props) => {
  const PAPER_FORM_REGISTERED_CARD_STATUS = 1;
  const nickNames = useSelector((reduxState) =>
    reduxState.user.fareMedias.map((item) => item.nickName)
  );

  const { fields, state, setState, toNextStep, toFirstStep, t } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setIsError] = useState('');
  const [inlineError, setInlineError] = useState('');

  let runValidation;

  useEffect(() => {
    if (runValidation) {
      runValidation();
    }
  }, [t]);

  return (
    <>
      <Modal
        title={fields.cancelMessageTitle?.value}
        text={fields.cancelMessage?.value}
        textCancel={fields.confirmCancellationButtonText?.value}
        textSubmit={fields.abortCancellationButtonText?.value}
        useRevert
        onSubmit={toFirstStep}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />

      <div className="add-card-header">
        <Text field={fields.heading} />
      </div>
      <div className="add-card add-card-step2">
        <div className="add-card-content">
          {state.CardStatus === PAPER_FORM_REGISTERED_CARD_STATUS ? (
            <div className="add-card-content-text" aria-label={fields.pinFormPaperTextA11y.value}>
              <Text field={fields.pinFormPaperText} />
            </div>
          ) : (
            <div className="add-card-content-text" aria-label={fields.pinFormTextA11y.value}>
              <Text field={fields.pinFormText} />
            </div>
          )}
          <div className="add-card-line">
            <hr />
          </div>
          <div className="add-card-note">
            <Note Title={() => <span>{fields.pinInputRuleDescription.value}</span>} />
          </div>
        </div>

        {error ? <Error small title={error} /> : null}
        <div className="add-card-content-form">
          {state.CardStatus === PAPER_FORM_REGISTERED_CARD_STATUS ? (
            <Formik
              initialValues={{
                Pin: '',
              }}
              validateOnChange={false}
              validationSchema={Yup.object().shape({
                Pin: Yup.string()
                  .required(t('addCardValidationMissingNewPIN'))
                  .matches(/^[0-9]{4}$/, t('addCardValidationNewPin')),
              })}
              onSubmit={(values) => {
                api
                  .validatePin({
                    Pin: values.Pin,
                    MediaId: state.MediaId,
                  })
                  .then((response) => {
                    if (response.data.Success) {
                      setState({
                        ...state,
                        Pin: values.Pin,
                        NickName: values.NickName,
                      });
                      toNextStep();
                    } else {
                      handleServerSuccessError(
                        response.data.Error,
                        setIsError,
                        null,
                        setInlineError
                      );
                    }
                  });
              }}
            >
              {({ errors, touched, handleSubmit, validateForm }) => {
                runValidation = validateForm;

                return (
                  <form onSubmit={handleSubmit}>
                    <div className="add-card-form-wrapper associated-to-card">
                      <div className="add-card-input">
                        <TextInput
                          type="password"
                          name="Pin"
                          errors={errors}
                          touched={touched}
                          label={fields.enterPinInputFieldLabel}
                          bellowText={fields.pinInputNoteText}
                          length={4}
                          serverError={inlineError}
                        />
                      </div>
                    </div>
                    <div className="break" aria-label={fields.pinFormNoteTextA11y.value}>
                      <div className="add-card-bottom-text">
                        <Text field={fields.pinFormNoteText} />
                      </div>
                      <ButtonBlock>
                        <ButtonBlock right>
                          <Button type="submit" buttonTextA11y={fields.nextButtonTextA11y.value}>
                            <Text field={fields.nextButtonText} />
                          </Button>
                          <Button
                            type="button"
                            onClick={() => setIsModalOpen(true)}
                            firstOrder
                            white
                            buttonTextA11y={fields.cancelButtonTextA11y.value}
                          >
                            <Text field={fields.cancelButtonText} />
                          </Button>
                        </ButtonBlock>
                      </ButtonBlock>
                    </div>
                  </form>
                );
              }}
            </Formik>
          ) : (
            <Formik
              initialValues={{
                Pin: '',
                ConfirmPin: '',
                NickName: '',
              }}
              validatmeOnChange={false}
              validationSchema={Yup.object().shape({
                Pin: Yup.string()
                  .required(t('addCardValidationMissingNewPIN'))
                  .matches(/^[0-9]{4}$/, t('addCardValidationNewPin')),
                ConfirmPin: Yup.string()
                  .required(t('addCardValidationMissingConfirmNewPin'))
                  .oneOf([Yup.ref('Pin'), null], t('addCardInputFormatInvalidConfirmPINFormat')),
                NickName: Yup.string()
                  .required(t('addCardValidationCreateUserName'))
                  .matches(/^[a-zA-Z0-9àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]{1,25}$/, t('addCardValidationCreateUserNameLength'))
                  .notOneOf(nickNames, t('addCardValidationNicknameUnique')),
              })}
              onSubmit={(values) => {
                setState({
                  ...state,
                  Pin: values.Pin,
                  NickName: values.NickName,
                });
                toNextStep();
              }}
            >
              {({ errors, touched, handleSubmit, validateForm }) => {
                runValidation = validateForm;

                return (
                  <form onSubmit={handleSubmit}>
                    <div className="add-card-form-wrapper">
                      <div className="add-card-input">
                        <TextInput
                          type="password"
                          name="Pin"
                          errors={errors}
                          touched={touched}
                          label={fields.pinInputFieldLabel}
                          bellowText={fields.pinInputNoteText}
                          length={4}
                        />
                      </div>
                      <div className="add-card-input">
                        <TextInput
                          type="password"
                          name="ConfirmPin"
                          errors={errors}
                          touched={touched}
                          label={fields.pinConfirmationInputFieldLabel}
                          length={4}
                        />
                      </div>
                      <div className="add-card-input">
                        <TextInput
                          name="NickName"
                          errors={errors}
                          touched={touched}
                          label={fields.nicknameInputFieldLabel}
                          bellowText={fields.nicknameInputNoteText}
                          length={25}
                        />
                      </div>
                    </div>
                    <div className="break">
                      <div
                        className="add-card-bottom-text"
                        aria-label={fields.pinFormNoteTextA11y.value}
                      >
                        <Text field={fields.pinFormNoteText} />
                      </div>
                      <ButtonBlock>
                        <ButtonBlock right>
                          <Button
                            type="submit"
                            className="button"
                            buttonTextA11y={fields.nextButtonTextA11y.value}
                          >
                            <Text field={fields.nextButtonText} />
                          </Button>
                          <Button
                            onClick={() => setIsModalOpen(true)}
                            white
                            firstOrder
                            buttonTextA11y={fields.cancelButtonTextA11y.value}
                          >
                            <Text field={fields.cancelButtonText} />
                          </Button>
                        </ButtonBlock>
                      </ButtonBlock>
                    </div>
                  </form>
                );
              }}
            </Formik>
          )}
        </div>
      </div>
    </>
  );
};

export default AddPrestoCard1;

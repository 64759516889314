import React, { useState, useEffect } from 'react';

import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Text, Placeholder, RichText } from '@sitecore-jss/sitecore-jss-react';

import api from '../../../services/api';
import {
  getServerUrl,
  getLongLanguageCode,
  formatDecimalWithCurrency,
  isJsonString,
} from '../../../services/utils';
import sharedFormConstants from '../../../constants/sharedFormConstants';
import { ButtonBlock, Button, InlineError, RadioInput, Loader } from '../../../atoms';
import { CardBlock, TextInput, Error, PaymentModal } from '../../../molecules';

import { setMediaCurrentBalance } from '../../../redux/user/actions';
import './DeactivateAndTransferNew2.scss';
import {
  setOrderCardResponse,
  setPaymentResponse,
  setNewCardDetails,
} from '../../../redux/user/actions';

const DeactivateAndTransferNew2 = (props) => {
  const customerId = useSelector((reduxState) => reduxState.user.customerId);
  const email = useSelector((reduxState) => reduxState.user.email);
  const [getSelectedCardvar, setSelectedCardvar] = useState("");
  const configKeys = useSelector((reduxState) => reduxState.settings.configKeys);
  const { mediaId, visibleId } = useSelector((reduxState) => reduxState.user.selectedCard);
  const [isLoading, setIsloading] = useState(false);
  let fareMedias = useSelector((reduxState) => reduxState.user.fareMedias);
  fareMedias = fareMedias.find((data) => data.visibleId === visibleId);
  const {
    fields,
    state,
    toPreviousStep,
    toNextStep,
    setState,
    t,
    returnUrl,
    setIsError,
    isError,
    rendering,
    userInfo,
    isTransferBalance,
    setIsTransferBalance,
    callRefreshToken,
  } = props;
  const [showCard, setShowCard] = useState({ paymenMethodsList: [] });
  const [encCorelationId, setEncCorelationId] = useState('');
  const [encMonerisOrder, setEncMonerisOrder] = useState('');
  const [encChargeTotal, setEncChargeTotal] = useState('');
  const [totalAmt, setTotalAmt] = useState(0);
  const [encOrderId, setEncOrderId] = useState('');
  const [monerisAfmsSalesId, setMonerisAfmsSalesId] = useState('');
  const [isSavedPaymentSetForCustomer, setSavedPaymentSetForCustomer] = useState(false);
  const [isSavedPaymentSetForCustomerSelected, setSavedPaymentSetForCustomerSelected] = useState(
    false
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [regFormOfPaymRecId, setRegFormOfPaymRecId] = useState('');
  const [isGetTicketFetching, setIsGetTicketFetching] = useState(false);
  const [isGetTicketFetchError, setIsGetTicketFetchError] = useState('');
  const [IsCachedApiFailure, setIsCachedApiFailure] = useState('');
  const [hasPADInSPM, setHasPADInSPM] = useState(false);
  const [isDebitError, setIsDebitError] = useState('');
  const [isOrderCard, setIsOrderCard] = useState(false);
  const currentAddress = useSelector((reduxState) => reduxState.user.currentShippingDetails);
  const addressType = useSelector((reduxState) => reduxState.user.addressType);
  const newAddress = useSelector((reduxState) => reduxState.user.newShippingDetails);
  const userName = useSelector((reduxState) => reduxState.user.userName);
  const dispatch = useDispatch();
  const totalAmount = state?.total;
  const [checkedCard, setCheckedCard] = useState("");
  const [processdone, setProcessdone] = useState(false);

  let prestoCardFund = fields?.UseThePrestCardFund.value;
  prestoCardFund =
    prestoCardFund.substring(0, prestoCardFund.length - 1) +
    fareMedias.currentBalance +
    prestoCardFund.substring(prestoCardFund.length - 1);

  const prestoCardFundNew = { value: prestoCardFund };
  const subTotalValue = fields.PriceValue.value;
  const PrestocardPrice =
    configKeys &&
    configKeys.filter((item) => item.key === 'PrestocardPrice').map((config) => config.setting);

  // useEffect(() => {
  //   setIsloading(true)
  //   document.body.scrollTop = 0;
  //   api
  //     .getRegisterPaymentMeansForCustomer({
  //       CustomerId: customerId,
  //       VisibleId: userInfo.selectedCard.visibleId,
  //     })
  //     .then((response) => {
  //       if (response.data?.Success) {
  //         // if (response.data.RegisterPaymentMeansList[0]?.RegFormOfPaymRecId) {
  //         // setSavedPaymentSetForCustomer(true);
  //         let registerPaymentMeansList = response.data.RegisterPaymentMeansList;
  //         if (registerPaymentMeansList.length > 0) {
  //           setSavedPaymentSetForCustomer(true);
  //           let padInSPM = registerPaymentMeansList?.filter((key, index) => key.AccountType == 0);
  //           let cardInSPM = registerPaymentMeansList?.filter((key, index) => key.AccountType == 1);
  //           setShowCard({
  //             paymenMethodsList: registerPaymentMeansList,
  //           });
  //           setIsloading(false);
  //           setHasPADInSPM(padInSPM.length > 0);
  //           //To pre-select radio button on pageload
  //           if (padInSPM.length > 0) {
  //             var id = fields.AuthorizedLabel.value;
  //             document.getElementById(id).checked = true;
  //             setState({
  //               ...state,
  //               selectedCard: sharedFormConstants.cardOptionPreAuth,
  //               regFormOfPaymRecId: padInSPM[0].RegFormOfPaymRecId,
  //             });
  //           } else {
  //             var id = fields.DebitCreditCard.value + ' ' + fields.SavedPaymentMethod.value;
  //             document.getElementById(id).checked = true;
  //             setState({ ...state, selectedCard: sharedFormConstants.cardOptionDebit });
  //           }
  //         }
  //         else {
  //           setIsloading(false);
  //           var id = fields.DebitCreditCard.value + ' ';
  //           document.getElementById(id).checked = true;
  //           setState({ ...state, selectedCard: sharedFormConstants.cardOptionDebit });
  //         }
  //       };
  //       setIsloading(false);
  //     });
  // }, []);

  useEffect(() => {
    document.body.scrollTop = 0;
    api
      .getRegisterPaymentMeansForCustomer({
        CustomerId: customerId,
        VisibleId: userInfo.selectedCard.visibleId,
      })
      .then((response) => {
        if (response.data?.Success) {
          let registerPaymentMeansList = response.data.RegisterPaymentMeansList;
          let padInSPM = registerPaymentMeansList?.filter((key, index) => key.AccountType == 0);
          let cardInSPM = registerPaymentMeansList?.filter((key, index) => key.AccountType == 1);
          if (cardInSPM.length > 0) setSavedPaymentSetForCustomer(true);
          setShowCard({
            paymenMethodsList: registerPaymentMeansList,
          });
          setIsloading(false);
          setHasPADInSPM(padInSPM.length > 0);
          // To pre-select radio button on pageload
          if (cardInSPM.length > 0 && fareMedias.currentBalance < PrestocardPrice && padInSPM.length == 0) {
            let id = `${fields.DebitCreditCard.value} ${fields.SavedPaymentMethod.value}`;
            let el = document.getElementById(id);
            if (el) el.checked = true;
            setCheckedCard(id);
            setState({
              ...state, selectedCard: sharedFormConstants.cardOptionDebit
            });
          }
          else if (padInSPM.length > 0 && fareMedias.currentBalance < PrestocardPrice) {
            let id = fields.AuthorizedLabel.value;
            let el = document.getElementById(id);
            if (el) el.checked = true;
            setCheckedCard(id);
            setState({
              ...state,
              selectedCard: sharedFormConstants.cardOptionPreAuth,
              regFormOfPaymRecId: padInSPM[0].RegFormOfPaymRecId,
            });
          }
          else if (fareMedias.currentBalance >= PrestocardPrice) {
            let id = `${prestoCardFund}`;
            let el = document.querySelector(`[aria-label="${id}"]`);
            if (el) el.checked = true;
            setCheckedCard(id);
            setState({
              ...state, selectedCard: sharedFormConstants.cardOptionFunds,
              regFormOfPaymRecId: ""
            });
          }
          else {
            let id = `${fields.DebitCreditCard.value} `;
            let el = document.getElementById(id);
            if (el) el.checked = true;
            setCheckedCard(id);
            setState({
              ...state, selectedCard: sharedFormConstants.cardOptionDebit
            });
          }
          setSavedPaymentSetForCustomerSelected(true);
        }
        setIsloading(false);
      });
  }, []);


  useEffect(() => {
    if (processdone)
      setCheckboxState(checkedCard);

  }, [processdone]);

  const setCheckboxState = (id) => {
    const element = document.getElementById(id);
    if (id && element) {
      element.checked = true;
    }
  };

  const monerisEnvironment =
    configKeys &&
    configKeys.filter((item) => item.key === 'Moneris.Environment').map((config) => config.setting);

  const postForm = () => {
    document.getElementById('frmPayment').submit();
  };

  const createHotListMediaForReplaceCard = (data) => {
    const payload = {
      CustomerId: userInfo.customerId,
      HotListCardCustomerId: fareMedias?.ChildCustomerID,
      MediaId: userInfo.selectedCard.mediaId,
      VisibleId: userInfo.selectedCard.visibleId,
      Email: data.username,
      PurchaseFromPrestoCard: data.cardOption === sharedFormConstants.cardOptionFunds,
      PurchaseFromSavedPayment: data.cardOption === sharedFormConstants.cardOptionSaved,
      purchasefromAdhocPayment: data.cardOption === sharedFormConstants.cardOptionDebit,
      PaymentMeanId: `${state?.regFormOfPaymRecId}`,
      IsStubEnabled: 'true',
      IsErrorFlowEnabled: 'false',
      ShippingAddress: {
        FirstName: addressType === "new" ? newAddress.FirstName : null,
        LastName: addressType === "new" ? newAddress.LastName : null,
        Line1: addressType === "new" ? newAddress.Line1 : null,
        Line2: addressType === "new" ? newAddress.Line2 : null,
        City: addressType === "new" ? newAddress.City : null,
        PostalCode: addressType === "new" ? newAddress.PostalCode : null,
        ShippingEmail: addressType === "new" ? newAddress.ShippingEmail : null,
        ShoppingstrCartId: addressType === "new" ? newAddress.ShoppingstrCartId : null,
        CustomerId: userInfo.customerId,
        VisibleId: userInfo.selectedCard.visibleId,
        MediaId: userInfo.selectedCard.mediaId,
        CountryId: addressType === "new" ? newAddress.CountryId : null,
        CountryName: addressType === "new" ? newAddress.CountryName : null,
        AreaName: addressType === "new" ? newAddress.AreaName : null,
        AreaCode: addressType === "new" ? newAddress.AreaCode : null,
        AreaId: addressType === "new" ? newAddress.AreaCode : null,
      },
    };
    if (isTransferBalance) {
      payload.IsTransferBalance = 'true';
    }
    api
      .createHotlistMediaForReplaceCard(payload)
      .then((response) => {
        dispatch(setOrderCardResponse(response));
        setIsloading(false);
        callRefreshToken(() => {
          toNextStep();
        });
      })
      .catch((error) => {
        // console.log('error');
        dispatch(setOrderCardResponse(error));
        setIsloading(false);
        toNextStep();
      })
      .finally(() => {
        setIsloading(false);
        setProcessdone(true);
      });
  };

  function handleCloseModal() {
    api
      .Cancelcachedorderfrompaymentfailure({
        Ticket: state.TicketResponse,
        Amount: totalAmt,
        AFMSSalesId: state.MonerisAfmsSalesId,
        CustomerId: customerId,
        VisibleId: visibleId,
        userName,
        EncMonerisOrder: state.encMonerisOrder,
        EncCorelationId: state.encCorelationId,
      })
      .then((response) => {
        if (response.data) {
          setIsloading(false);
        }
      });
    setIsModalOpen(false);
  }

  const handleCheckoutModal = (response) => {
    setIsloading(true);
    // setIsGetTicketFetching(true);
    const totalCardAmount = response.data.Order.TotalAmount;
    const encCorrelationId = response.data.Order.EncCorrelationId;
    const encMonerisOrder = response.data.EncMonerisOrder;
    const encOrderAmount = response.data.Order.EncOrderAmount;
    const encOrderId = response.data.Order.EncOrderId;
    const aFMSSalesId = response.data.Order.AFMSSalesId;
    api
      .getTicket({
        PaymentProfileType: 'PURCHASE',
        OrderNumber: aFMSSalesId,
        CustomerId: customerId,
        Amount: totalCardAmount,
        VisibleId: visibleId,
        userName,
      })
      .then((response) => {
        if (response.data.Success === true) {
          setIsloading(false);
          setIsModalOpen(true);
          setIsGetTicketFetching(true);
          let myCheckout = new window.monerisCheckout();
          myCheckout.setMode(monerisEnvironment[0]);
          myCheckout.setCheckoutDiv('monerisCheckout');
          myCheckout.setCallback('page_loaded', myPageLoad);
          myCheckout.setCallback('cancel_transaction', myCancelTransaction);
          myCheckout.setCallback('error_event', myErrorEvent);
          myCheckout.setCallback('payment_receipt', myPaymentReceipt);
          myCheckout.setCallback('payment_complete', myPaymentComplete);
          myCheckout.startCheckout(response.data.TicketResponse.ticket);
          const ticketResponse = response.data.TicketResponse.ticket;
          setState({
            ...state,
            TicketResponse: ticketResponse,
            MonerisAfmsSalesId: aFMSSalesId,
            encMonerisOrder,
            encCorelationId: encCorrelationId,
          });

          function myPageLoad() {
            setIsGetTicketFetching(false);
          }

          function myCancelTransaction() {
            handleCloseModal();
            if (checkedCard && document.getElementById(checkedCard)) {
              document.getElementById(checkedCard).checked = true;
            }
          }

          function myPaymentComplete(args) {
            document.getElementById('monerisCheckout').innerHTML = '';
            document.getElementById('monerisCheckout').style = '';
            const parsedData = isJsonString(args) ? JSON.parse(args) : '';
            if (parsedData?.response_code !== '001') {
              setIsModalOpen(false);
              setIsDebitError(t('monerisPaymentError'));
            } else {
              setIsModalOpen(false);
              setIsloading(true);
              api
                .CompleteReplacementOrderwithTicket({
                  Ticket: ticketResponse,
                  Amount: totalCardAmount,
                  AFMSSalesId: aFMSSalesId,
                  CustomerId: customerId,
                  VisibleId: visibleId,
                  userName,
                  EncMonerisOrder: encMonerisOrder,
                  EncCorelationId: encCorrelationId,
                })
                .then((response) => {
                  if (response.data.Success === true) {
                    api.UpdateFareMediaSession({ VisibleId: visibleId }).then(({ data }) => { });
                    const transactionDateTime =
                      response.data.Order.Payment.Details.TransactionDateTime;
                    const transactionType = TransactionCodeValue(
                      response.data.Order.Payment.Details.TransactionCode
                    );
                    dispatch(setOrderCardResponse(response));
                    const isoCode = response.data.Order.Payment.Details.ISOCode;
                    const responseCode = response.data.Order.Payment.Details.ResponseCode;
                    const acceptanceStatus = response.data.Order.Payment.Details.AcceptanceStatus;
                    const transactionNumber = response.data.Order.Payment.Details.TransactionNumber;
                    setIsloading(false);
                    setIsModalOpen(false);
                    setIsGetTicketFetching(false);
                    setState({
                      ...state,
                      AFMSSalesId: aFMSSalesId,
                      TransactionDateTime: transactionDateTime,
                      TransactionType: transactionType,
                      ISOCode: isoCode,
                      ResponseCode: responseCode,
                      AcceptanceStatus: acceptanceStatus,
                      TransactionNumber: transactionNumber,
                    });
                    callRefreshToken(() => {
                      toNextStep();
                    });
                  } else {
                    setIsloading(false);
                    setIsModalOpen(false);
                    setIsTransferBalance(true);
                    const parsedError = isJsonString(response?.data?.Error)
                      ? JSON.parse(response.data.Error)
                      : '';
                    setIsDebitError(parsedError?.Description);
                    if (checkedCard && document.getElementById(checkedCard)) {
                      document.getElementById(checkedCard).checked = true;
                    }
                  }
                });
            }
          }

          function myPaymentReceipt(args) {
            myPaymentComplete(args);
          }

          function myErrorEvent() {
            api
              .Cancelcachedorderfrompaymentfailure({
                Ticket: ticketResponse,
                Amount: totalCardAmount,
                AFMSSalesId: aFMSSalesId,
                CustomerId: customerId,
                VisibleId: visibleId,
                userName,
                EncMonerisOrder: encMonerisOrder,
                EncCorelationId: encCorrelationId,
              })
              .then((response) => {
                if (response.data) {
                  setIsloading(false);
                }
              });
            setIsModalOpen(false);
            const parsedError = isJsonString(response?.data?.Error)
              ? JSON.parse(response.data.Error)
              : '';
            setIsDebitError(parsedError?.Description);
          }
        } else {
          setIsloading(false);
          setIsGetTicketFetching(false);
          setIsModalOpen(false);
          const parsedError = isJsonString(response?.data?.Error)
            ? JSON.parse(response.data.Error)
            : '';
          setIsGetTicketFetchError(parsedError?.Description);
        }
      });
  };
  const getEpurseBalance = (data) => { 
    const payload = {
      visibleId: userInfo.selectedCard.visibleId
    }
    api
      .getCurrentBalance(payload)
      .then((response) => {
        if (response.data.Success === true) {
          
          if (response.data.EpurseBalance > PrestocardPrice) {
            dispatch(setMediaCurrentBalance({ visibleId: payload.visibleId, currentBalance: response?.data?.EpurseBalance }));
            toPreviousStep();
          }
          else {
            createCachedOrder(data);
          }
        }
        else {
          toPreviousStep();
        }
      })
  };

  const createCachedOrder = (data) => {
    setIsloading(true);
    const payload = {
      CustomerId: userInfo.customerId,
      HotListCardCustomerId: fareMedias?.ChildCustomerID,
      MediaId: userInfo.selectedCard.mediaId,
      VisibleId: userInfo.selectedCard.visibleId,
      IsStubEnabled: 'true',
      IsErrorFlowEnabled: 'false',
      PurchaseFromPrestoCard: data.cardOption === sharedFormConstants.cardOptionFunds,
      PurchaseFromSavedPayment: data.cardOption === sharedFormConstants.cardOptionSaved,
      ShippingAddress: {
        FirstName: addressType === "new" ? newAddress.FirstName : null,
        LastName: addressType === "new" ? newAddress.LastName : null,
        Line1: addressType === "new" ? newAddress.Line1 : null,
        Line2: addressType === "new" ? newAddress.Line2 : null,
        City: addressType === "new" ? newAddress.City : null,
        PostalCode: addressType === "new" ? newAddress.PostalCode : null,
        ShippingEmail: addressType === "new" ? newAddress.ShippingEmail : null,
        ShoppingstrCartId: addressType === "new" ? newAddress.ShoppingstrCartId : null,
        CustomerId: userInfo.customerId,
        VisibleId: userInfo.selectedCard.visibleId,
        MediaId: userInfo.selectedCard.mediaId,
        CountryId: addressType === "new" ? newAddress.CountryId : null,
        CountryName: addressType === "new" ? newAddress.CountryName : null,
        AreaName: addressType === "new" ? newAddress.AreaName : null,
        AreaCode: addressType === "new" ? newAddress.AreaCode : null,
        AreaId: addressType === "new" ? newAddress.AreaCode : null,
      },
      // PaymentMeanId: state?.regFormOfPaymRecId,
      ShippingDetailsForReplacement: 'xyz',
    };
    if (isTransferBalance) {
      payload.IsTransferBalance = 'true';
    }
    api
      .createCachedOrderForReplacement(payload)
      .then((response) => {
        // if (response.status !== 200) {
        //   setIsloading(false);
        //   throw Error(response.statusText);
        // }
        api
          .UpdateFareMediaSession({ VisibleId: userInfo?.selectedCard?.visibleId })
          .then(({ data }) => { });
        if (response.data.Success === true) {
          setIsloading(false);
          dispatch(setPaymentResponse(response));
          setTotalAmt(response.data.Order.TotalAmount);
          setState({
            ...state,
            savedPaymentMethodUsed: false,
          });
        } else {
          setIsloading(false);
          // throw Error(response.statusText);
          const parsedError = isJsonString(response?.data?.Error)
            ? JSON.parse(response.data.Error)
            : '';
          setIsCachedApiFailure(parsedError.Description !== '' ? parsedError.Description : fields?.AFMSCRTTE1.value);
        }
        if (response.data && data.cardOption === sharedFormConstants.cardOptionDebit) {
          setIsloading(false);
          dispatch(setNewCardDetails(response?.data?.Order));
          setEncCorelationId(response.data.Order.EncCorrelationId);
          setEncMonerisOrder(response.data.EncMonerisOrder);
          setEncChargeTotal(response.data.Order.EncOrderAmount);
          setEncOrderId(response.data.Order.EncOrderId);
          setMonerisAfmsSalesId(response.data.Order.AFMSSalesId);
          setState({
            ...state,
            savedPaymentMethodUsed: false,
          });
        }
        return response;
      })
      .then((response) => {
        if (response.data.Success === true) {
          setIsError(false);
          if (data.cardOption === sharedFormConstants.cardOptionDebit) {
            handleCheckoutModal(response);
          } else {
            toNextStep();
          }
        }
        else {
          setIsloading(false);
          setIsCachedApiFailure(fields?.AFMSCRTTE1.value);
        }
        setIsloading(false);
      })
      .catch((error) => {
      })
      .finally(() => {
        setIsloading(false);
        setProcessdone(true);
      });
  };

  const TransactionCodeValue = (transactionCode) => {
    let transactionType;
    switch (transactionCode) {
      case '0':
      case '00':
        transactionType = 'Purchase';
        break;
      case '1':
      case '01':
        transactionType = 'Pre-Authorization';
        break;
      case '6':
      case '06':
        transactionType = 'Card Verification';
        break;
      default:
        transactionType = transactionCode;
    }
    return transactionType;
  };
  const getDefaultPayment = () => {
    if (fareMedias.currentBalance >= PrestocardPrice) {
      return sharedFormConstants.cardOptionFunds;
    }
    if (hasPADInSPM) {
      return sharedFormConstants.cardOptionPreAuth;
    }
    return sharedFormConstants.cardOptionDebit;
  };
  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <PaymentModal
            title={fields.PaymentDetailsTitle.value}
            cautionMessage={t('monerisPaymentCautionMessage')}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            isGetTicketFetching={isGetTicketFetching}
            disableBackdropClick
            disableEscapeKeyDown
            handleCloseModal={handleCloseModal}
          />
          {addressType === 'current' ? (
            <div className="shipping-add-details">
              <div className="shipping-header">
                <Text field={fields.ShippingAddressTitle} />
              </div>
              <div>
                <div className="shipping-block">
                  <RichText field={fields.ShippingAddressSubTxt} />
                </div>
                <div className="shipping-street">
                  <p>
                    {currentAddress?.Line1} {currentAddress?.Line2}
                  </p>
                  <p>
                    {currentAddress?.City}, {currentAddress?.Area?.Name}
                  </p>
                  <p>{currentAddress?.Country?.Id}</p>
                  <p>{currentAddress?.PostalCode}</p>
                </div>
              </div>
            </div>
          ) : (
            <div className="shipping-add-details">
              <div className="shipping-header">
                <Text field={fields.ShippingAddressTitle} />
              </div>
              <div>
                <div className="shipping-block">
                  <RichText field={fields.ShippingAddressSubTxt} />
                </div>
                <div className="shipping-street">
                  <p>
                    {newAddress?.Line1} {newAddress?.Line2}
                  </p>
                  <p>
                    {newAddress?.City}, {newAddress?.AreaName}
                  </p>
                  <p>{newAddress?.CountryId}</p>
                  <p>{newAddress?.PostalCode}</p>
                </div>
              </div>
            </div>
          )}
          <Formik
            enableReinitialize
            initialValues={{
              [sharedFormConstants.terms]: false,
              [sharedFormConstants.cardOption]: getDefaultPayment(),
              terms: false,
              username: email,
            }}
            validationSchema={Yup.object().shape({
              [sharedFormConstants.terms]: Yup.boolean().oneOf(
                [true],
                fields.TermsandConditionError.value
              ),
              username: Yup.string()
                .min(6, t('checkoutInvalidEmail'))
                .matches(
                  /^[\w+_-]+(?:\.[\w+_-]+)*@(?:[\w\d-]+\.)+\w{2,6}$/,
                  t('checkoutInvalidEmail')
                ),
            })}
            onSubmit={(values) => {
              // if (!values.username) {
              //   return false;
              // }
              setProcessdone(false);
              const newState = { ...state };
              if (newState.selectedCard === sharedFormConstants.cardOptionPreAuth) {
                setSavedPaymentSetForCustomerSelected(true);
                setIsloading(true);
                values.cardOption = (getSelectedCardvar != "") ? getSelectedCardvar : values.cardOption;
                createHotListMediaForReplaceCard(values);
              }
              else if (newState.selectedCard === sharedFormConstants.cardOptionFunds && fareMedias.currentBalance >= PrestocardPrice) {
                values.cardOption = (getSelectedCardvar != "") ? getSelectedCardvar : values.cardOption;
                createCachedOrder(values);
              }
              else {
                values.cardOption = (getSelectedCardvar != "") ? getSelectedCardvar : values.cardOption;
                //createCachedOrder(values);
                getEpurseBalance(values);
              }
            }}
          >
            {({ errors, touched, handleSubmit, values }) => {
              // const checkOption =
              //   values[sharedFormConstants.cardOption] === sharedFormConstants.cardOptionDebit;
              // const savedCheckOption =
              //   values[sharedFormConstants.cardOption] === sharedFormConstants.cardOptionSaved;

              return (
                <form onSubmit={handleSubmit}>
                  <CardBlock title={fields.EmailAddressTitle.value}>
                    <RichText field={fields.EmailAddressSubTitle} />
                    <div className="checkout-textInput">
                      <TextInput
                        errors={errors}
                        touched={touched}
                        name="username"
                        label={fields.EmailAddressTitle}
                        inputA11y={fields.EmailAddressTitle.value}
                      />
                    </div>
                    {errors[sharedFormConstants.username] &&
                      touched[sharedFormConstants.username] && (
                        <InlineError cssClass="checkbox-error">
                          {errors[sharedFormConstants.username]}
                        </InlineError>
                      )}
                    <div className="checkout-text">
                      <Text field={fields.EmailAddressSubLabel} />
                    </div>
                  </CardBlock>
                  {isError ? (
                    <Error
                      small
                      title={
                        isSavedPaymentSetForCustomerSelected
                          ? t('savedPaymentCouldNotBeProcessed')
                          : t('directPaymentCouldNotBeProcessed')
                      }
                    />
                  ) : null}
                  {isGetTicketFetchError ? <Error small title={isGetTicketFetchError} /> : null}
                  {isDebitError ? <Error small title={isDebitError} /> : null}
                  {IsCachedApiFailure ? <Error small title={IsCachedApiFailure} /> : null}
                  <CardBlock title={fields.PaymentMethodTitle.value}>
                    <div
                      className="payment-text"
                      id="radio-group"
                      tabIndex="0"
                      role="application"
                      aria-label={fields.PaymentMethodSubTitle.value}
                    >
                      <Text field={fields.PaymentMethodSubTitle} />
                    </div>
                    {fareMedias.currentBalance >= PrestocardPrice && (
                      <div className="radio-card-Deactivate-payment">
                        <RadioInput
                          name={sharedFormConstants.cardOption}
                          value={sharedFormConstants.cardOptionFunds}
                          a11y={prestoCardFund}
                          // onChange={(e) => {
                          //   setState({ ...state, selectedCard: e.target.value });
                          // }}
                          checked={values.cardOption === sharedFormConstants.cardOptionFunds}
                        />
                        <div className="new-card-content">
                          <RichText className="content-title" field={prestoCardFundNew} />
                        </div>
                      </div>
                    )}

                    {/* <div className="payment-radio">
                      <RadioInput
                        label={prestoCardFund}
                        a11y={prestoCardFund}
                        name={sharedFormConstants.cardOption}
                        value={sharedFormConstants.cardOptionFunds}
                      />
                    </div> */}

                    {fareMedias.currentBalance < PrestocardPrice && (
                      <div>
                        <div className="payment-radio">
                          <RadioInput
                            label={`${fields.DebitCreditCard.value} ${isSavedPaymentSetForCustomer ? fields.SavedPaymentMethod.value : ''
                              }`}
                            a11y={`${fields.DebitCreditCard.value} ${isSavedPaymentSetForCustomer ? fields.SavedPaymentMethod.value : ''
                              }`}
                            name={sharedFormConstants.cardOption}
                            value={sharedFormConstants.cardOptionDebit}
                            onChange={(e) => {
                              setState({ ...state, selectedCard: e.target.value });
                              values.cardOption = e.target.value;
                              setSelectedCardvar(e.target.value);
                              setCheckedCard(e.target.id);
                            }}
                          //checked={values.cardOption === sharedFormConstants.cardOptionDebit}
                          />
                        </div>
                        {hasPADInSPM && (
                          <div className="payment-radio">
                            {showCard &&
                              showCard.paymenMethodsList &&
                              showCard.paymenMethodsList.map((card, index) => {
                                if (card.AccountType === 0) {
                                  return (
                                    <RadioInput
                                      label={fields.AuthorizedLabel.value}
                                      a11y={fields.AuthorizedLabel.value}
                                      name={sharedFormConstants.cardOption}
                                      value={sharedFormConstants.cardOptionPreAuth}
                                      onChange={(e) => {
                                        setState({
                                          ...state,
                                          selectedCard: e.target.value,
                                          regFormOfPaymRecId: card.RegFormOfPaymRecId,
                                        });
                                        values.cardOption = e.target.value;
                                        setSelectedCardvar(e.target.value);
                                        setCheckedCard(e.target.id);
                                      }}
                                    /*checked={
                                      values.cardOption === sharedFormConstants.cardOptionPreAuth
                                    }*/
                                    />
                                  );
                                }
                              })}
                          </div>
                        )}
                      </div>
                    )}
                    <div className="checkout-terms-block">
                      <Placeholder name="presto-terms-and-conditions" rendering={rendering} />
                      {errors[sharedFormConstants.terms] && touched[sharedFormConstants.terms] && (
                        <InlineError cssClass="checkbox-error">
                          {errors[sharedFormConstants.terms]}
                        </InlineError>
                      )}
                    </div>
                    <div className="checkout-payment-total">
                      <Text field={fields.TotalAmount} />
                      <span>{` ${formatDecimalWithCurrency(subTotalValue.substring(1))}`}</span>
                    </div>
                  </CardBlock>
                  <ButtonBlock>
                    <ButtonBlock right>
                      {values.cardOption === sharedFormConstants.cardOptionDebit ? (
                        <Button type="submit" buttonTextA11y={fields.NextCTALabelA11y.value}>
                          <Text field={fields.NextCTALabel} />
                        </Button>
                      ) : (
                        <Button type="submit" buttonTextA11y={fields.ConfirmCTALabelA11y}>
                          <Text field={fields.ConfirmCTALabel} />
                        </Button>
                      )}
                      <Button
                        onClick={() => toPreviousStep()}
                        firstOrder
                        white
                        buttonTextA11y={fields.GoBackCTALabel.value}
                      >
                        <Text field={fields.GoBackCTALabel} />
                      </Button>
                    </ButtonBlock>
                  </ButtonBlock>
                </form>
              );
            }}
          </Formik>

          <div>
            {configKeys &&
              configKeys
                .filter((item) => item.key === 'Cpg.Url')
                .map((configKey) => (
                  <form
                    method="POST"
                    id="frmPayment"
                    name="frmPayment"
                    style={{ display: 'none' }}
                    action={configKey.setting}
                  >
                    <input type="hidden" name="lang" id="lang" value={getLongLanguageCode()} />
                    <input
                      type="hidden"
                      name="LANGUAGE"
                      id="LANGUAGE"
                      value={getLongLanguageCode()}
                    />
                    <input
                      type="hidden"
                      name="enc_charge_total"
                      id="enc_charge_total"
                      value={encChargeTotal}
                    />
                    <input type="hidden" name="enc_order_id" id="enc_order_id" value={encOrderId} />
                    <input type="hidden" name="rvarIsAx" value="true" />
                    <input
                      type="hidden"
                      name="responseURL"
                      id="responseURL"
                      value={`${getServerUrl()}/apidata/Sales/CreateMonerisOrderForReplacementCard?encCorrelationId=${encCorelationId}&encMonerisOrder=${encMonerisOrder}&returnUrl=${returnUrl}`}
                    />
                    <input
                      type="hidden"
                      name="enc_correlation_id"
                      id="enc_correlation_id"
                      value={encCorelationId}
                    />
                  </form>
                ))}
          </div>
        </>
      )}
    </>
  );
};

export default withTranslation()(DeactivateAndTransferNew2);

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Formik } from 'formik';

import api from '../../services/api';
import { formatDecimalWithCurrency } from '../../services/utils';
import handleServerSuccessError from '../../services/handleServerSuccessError';
import { AutoloadGridItem, ButtonBlock, Button } from '../../atoms';
import { Error, CardBlock, TextInput } from '../../molecules';

export default function ModifyAutoload1({
  fields,
  toNextStep,
  toFirstStep,
  setState,
  t,
  userInfo,
  product,
}) {
  const businessRules = useSelector((state) => state.businessRules?.rules) || [];
  function validateThresholdAmount(value) {
    let thresholdAmountError;
    if (!value) {
      thresholdAmountError = t('autoloadInvalidDollarValue');
    } else if (value < parseFloat(BalanceDropMin)) {
      thresholdAmountError = t('autoloadBalanceThresholdMinimum');
    } else if (!/^\d+(\.\d{0,2})?$/i.test(value)) {
      thresholdAmountError = t('autoloadInvalidDollarValue');
    } else if (value > parseFloat(BalanceDropMax)) {
      thresholdAmountError = t('autoloadBalanceThresholdMaximum');
    }
    return thresholdAmountError;
  }

  function validateMaximumValue(values) {
    const { loadAmount = '', thresholdAmount = '' } = values;
    const maximumValue = parseFloat(loadAmount) + parseFloat(thresholdAmount);
    if (maximumValue > parseFloat(AddFundsMax)) {
      return {
        loadAmount: t('autoloadMaximumValue'),
        thresholdAmount: t('autoloadMaximumValue'),
      };
    }
    return {};
  }

  function validateLoadAmount(value) {
    let loadAmountError;
    const decimalValue = value.toString().split('.')[0];
    if (!value) {
      loadAmountError = t('autoloadInvalidDollarValue');
    } else if (!/^\d+(\.\d{0,2})?$/i.test(value)) {
      return t('autoloadInvalidDollarValue');
    } else if (value < parseFloat(AmountLoadMin)) {
      loadAmountError = t('autoloadAmountMinimum');
    } else if (value === parseFloat(AddFundsMax) || value > parseFloat(AmountLoadMax) || decimalValue % parseFloat(MultiplesForLoadAmount) !== 0) {
      loadAmountError = t('autoloadAmountMaximum');
    }
    return loadAmountError;
  }
  const [error, setIsError] = useState('');
  if (product === null) {
    return null;
  }
  const AddFundsMax = businessRules?.AddFundsMax ? businessRules?.AddFundsMax : 1000;
  const AmountLoadMin = businessRules?.AmountLoadMin ? businessRules?.AmountLoadMin : 20;
  const AmountLoadMax = businessRules?.AmountLoadMax ? businessRules?.AmountLoadMax : 990;
  const BalanceDropMin = businessRules?.BalanceDropMin ? businessRules?.BalanceDropMin : 5;
  const BalanceDropMax = businessRules?.BalanceDropMax ? businessRules?.BalanceDropMax : 60;
  const MultiplesForLoadAmount = businessRules?.MultiplesForLoadAmount ? businessRules?.MultiplesForLoadAmount : 10;

  return (
    <>
      <h1 className="autoload-header" tabIndex="0">
        <Text field={fields.heading} />
      </h1>
      {error ? <Error title={error} /> : null}
      <div className="loadMyCard-wrapper">
        <div className="autoload-tab">
          <CardBlock title={fields.currentAutoloadLabel.value}>
            <AutoloadGridItem
              leftLabel={fields.oldLoadLabel.value}
              leftAmount={formatDecimalWithCurrency(product.Amount)}
              rightLabel={fields.oldDropLabel.value}
              rightAmount={formatDecimalWithCurrency(product.Threshold)}
            />
          </CardBlock>
        </div>
      </div>
      <div className="loadMyCard-wrapper">
        <div className="autoload-tab">
          <div className="setup-autoload">
            <div
              className="autoload-title"
              role="heading"
              aria-level="1"
              aria-label={fields.newAutoloadLabelA11y.value}
            >
              <Text field={fields.newAutoloadLabel} />
            </div>
            <div className="autoload-subtext" aria-label={fields.newAutoloadDescriptionA11y.value}>
              <Text field={fields.newAutoloadDescription} />
            </div>
          </div>
          <Formik
            enableReinitialize
            initialValues={{
              loadAmount: '',
              thresholdAmount: '',
            }}
            validate={validateMaximumValue}
            validateOnChange={false}
            onSubmit={(values) => {
              api
                .isAutoloadAllowed({
                  visibleId: userInfo.selectedCard.visibleId,
                })
                .then((response) => {
                  if (response.data.Success) {
                    api
                      .isAutoloadAllowedWithBalance({
                        autoLoadAmount: values.loadAmount,
                        thresholdAmount: values.thresholdAmount,
                      })
                      .then((response2) => {
                        if (response2.data.Success) {
                          api
                            .setupAutoloadDetails({
                              autoloadStatementType: 0,
                              customerId: userInfo.customerId,
                              visibleId: userInfo.selectedCard.visibleId,
                              nickName: userInfo.selectedCard.nickName,
                              loadAmount: values.loadAmount,
                              thresholdAmount: values.thresholdAmount,
                            })
                            .then((response3) => {
                              if (response3.data) {
                                setState({
                                  loadAmount: values.loadAmount,
                                  thresholdAmount: values.thresholdAmount,
                                });
                                toNextStep();
                              } else {
                                handleServerSuccessError(response2.data.Error, setIsError);
                              }
                            });
                        } else {
                          handleServerSuccessError(response.data.Error, setIsError);
                        }
                      });
                  } else {
                    handleServerSuccessError(response.data.Error, setIsError);
                  }
                });
            }}
          >
            {({ errors, touched, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="autoload-form-wrapper">
                  <TextInput
                    withMoneyIcon
                    name="loadAmount"
                    errors={errors}
                    touched={touched}
                    bellowText={fields.amountUnderText}
                    length={6}
                    validateField={validateLoadAmount}
                    label={fields.newAmountInputLabel}
                  />

                  <TextInput
                    withMoneyIcon
                    name="thresholdAmount"
                    errors={errors}
                    touched={touched}
                    label={fields.newDropInputLabel}
                    bellowText={fields.dropUnderText}
                    length={6}
                    validateField={validateThresholdAmount}
                  />
                </div>

                <ButtonBlock>
                  <ButtonBlock right>
                    <Button type="submit" buttonTextA11y={fields.nextButtonTextA11y.value}>
                      <Text field={fields.nextButtonText} />
                    </Button>
                    <Button
                      firstOrder
                      white
                      buttonTextA11y={fields.cancelButtonTextA11y.value}
                      onClick={toFirstStep}
                    >
                      <Text field={fields.cancelButtonText} />
                    </Button>
                  </ButtonBlock>
                </ButtonBlock>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
